import { alertSelector, setAlertReducer } from '@slices';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import AudioPlayer from 'react-h5-audio-player';
import CircularProgress from '@mui/material/CircularProgress';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import IconButton from '@mui/material/IconButton';
import { Meters } from '@components';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import audioList from './../../mock/audio.list.json';
import classnames from 'classnames';

export default function AudioList({ lang, onMoveDown, onMoveUp }) {
  const dispatch = useDispatch();
  // Ref
  const wavesurfer = useRef(null);
  // State
  const [play, setPlay] = useState(0);
  const [audio, setAudio] = useState(101);
  const [value, setValue] = useState(0);
  const [meters, setMeters] = useState(0);
  const [loadingWave, setLoadingWave] = useState(true);
  const [showList, setShowList] = useState(false);
  const alert = useSelector(alertSelector);

  // Audio
  const tick = new Audio('audio/tick.wav');

  useEffect(() => {
    if (value === 17 && !alert) dispatch(setAlertReducer(true));
    if (value < 17 && alert) dispatch(setAlertReducer(false));
  }, [value, alert, dispatch]);

  const onClickPrev = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (value > 0) {
      onMoveUp(value - 1);
      tick?.play();
      setValue(value - 1);
      setMeters(audioList[value - 1]?.meters);
      setAudio(audioList[value - 1]?.code);
      setLoadingWave(true);
    }
  };
  const onClickNext = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (value < 17) {
      tick?.play();
      onMoveDown(value + 1);
      setValue(value + 1);
      setMeters(audioList[value + 1]?.meters);
      setAudio(audioList[value + 1]?.code);
      setLoadingWave(true);
    }
  };

  /* useEffect(() => {
    if (window.WaveSurfer && !wavesurfer.current) {
      wavesurfer.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'white',
        progressColor: 'cyan',
        barGap: 2,
        barWidth: 1,
        barRadius: 2,
        cursorWidth: 0,
        cursorColor: 'transparent',
        scrollParent: true,
        height: 25,
      });

      wavesurfer.current?.setVolume(0);
      wavesurfer.current?.load(`audio/${lang}/${audio}.mp3`);

      wavesurfer.current.on('ready', function () {
        console.log('ready');
        setLoadingWave(false);
      });



    } else {
      wavesurfer.current?.load(`audio/${lang}/${audio}.mp3`);
    }
  }, [value, window]); */

  return (
    <>
      <Meters number={meters} step={value} play={play} />

      <div className="fixed z-40 flex flex-col items-center justify-center w-screen h-full overflow-hidden select-none">
        <div className="flex flex-col items-center justify-center w-full max-w-sm space-y-8 select-none">
          <div className="relative flex flex-col items-center justify-center w-full">
            
           {/*  <div
              id="waveform"
              className={classnames("absolute -top-10 w-1/3 mx-auto duration-500 z-20 overflow-hidden transition-all rounded-lg pointer-events-none select-none",
                {
                  "scale-in-ver-center": !loadingWave,
                  "scale-out-vertical": loadingWave
                }
              )}
            /> */}
            <code className="flex items-center h-10 max-w-sm text-white uppercase">
              {audioList[value]?.name[lang]}
            </code>
          </div>

          <div className="z-50 flex space-x-6">
            <IconButton
              size="small"
              disabled={value === 0}
              disableTouchRipple={true}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={onClickPrev}
              className={classnames({ 'opacity-20': value === 0 })}
            >
              <SkipPreviousIcon
                fontSize="inherit"
                className="text-5xl text-white"
              />
            </IconButton>

            <span className="relative flex items-center justify-center w-32 h-32 overflow-hidden text-white rounded-lg bg-white/20 font-digital text-7xl backdrop-blur-sm">
              {value + 1}
            </span>

            <IconButton
              size="small"
              disabled={value === 17}
              disableTouchRipple={true}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={onClickNext}
              className={classnames('text-lg', { 'opacity-20': value === 17 })}
            >
              <SkipNextIcon
                fontSize="inherit"
                className="text-5xl text-white"
              />
            </IconButton>
          </div>
          <IconButton
            size="small"
            disableTouchRipple={true}
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => setShowList(true)}
            className={classnames('text-lg text-white')}
          >
            <FormatListNumberedRtlIcon />
          </IconButton>
        </div>

        
      </div>

      {!showList && (
        <AudioPlayer
          className="fixed bottom-0 z-50 w-screen pb-6 text-white shadow-none fill-white"
          autoPlay={false}
          showSkipControls={false}
          showJumpControls={false}
          onSeeking={(e) => {
            wavesurfer?.current?.setCurrentTime(e?.target?.currentTime);
          }}
          onClickPrevious={onClickPrev}
          onClickNext={onClickNext}
          customAdditionalControls={[]}
          src={`audio/${lang}/${audio}.mp3`}
          onPause={() => {
            //tick.play();
            setPlay(false);
            wavesurfer.current?.pause();
          }}
          onPlay={() => {
            //tick.play();
            setPlay(true);
            wavesurfer.current?.play();
          }}
        />
      )}

      <SwipeableDrawer
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
          },
        }}
        anchor="bottom"
        onOpen={() => null}
        open={showList}
        onClose={() => setShowList(false)}
        className="bg-none"
      >
        <ul
          className={classnames(
            'rounded-none bg-white/10 backdrop-blur-3xl',
            'h-28 w-full space-x-4 overflow-x-auto px-10',
            'flex items-center'
          )}
        >
          {audioList.map((a, i) => {
            return (
              <li
                key={i}
                className={classnames(
                  'flex items-center justify-center',
                  'text-xl text-white',
                  'space-x-4 rounded-lg'
                )}
              >
                <button
                  onClick={() => {
                    tick.play();

                    if (i > value) {
                      onMoveDown(i);
                    }
                    if (i < value) {
                      onMoveUp(i);
                    }

                    setValue(i);
                    setMeters(audioList[i]?.meters);
                    setAudio(audioList[i]?.code);
                    setLoadingWave(true);
                    setTimeout(() => setShowList(false), 200);
                  }}
                  className={classnames(
                    'h-12 w-12 cursor-pointer select-none',
                    'flex items-center justify-center',
                    'rounded-lg border border-cyan-400 font-digital',
                    {
                      'opacity-30': i !== value,
                    }
                  )}
                >
                  {i + 1}
                </button>
              </li>
            );
          })}
        </ul>
      </SwipeableDrawer>
    </>
  );
}
