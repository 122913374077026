
import classnames from 'classnames';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Intro({ showStart, introRef, onShowLangStep }) {
  
  
  return (
    <div
      className={classnames(
        'z-40 flex transform flex-col',
        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
      )}
    >
      <div className="space-y-10" ref={introRef}>
        <div>
          <span className="absolute flex text-white slide-in-bck-center -top-6 w-28">
            S 518
          </span>
          <h1
            className={classnames(
              'slide-in-bck-center transition-all delay-1000',
              'text-5xl font-extrabold uppercase leading-[2.6rem] text-white '
            )}
          >
            Nazario Sauro
          </h1>
        </div>

        <LoadingButton
          variant="contained"
          disabled={!showStart}
          loading={!showStart}
          onClick={onShowLangStep}
          className="absolute w-full h-8 slide-in-bck-center -bottom-14 bg-primary"
        >
          Start
        </LoadingButton>
      </div>
    </div>
  );
}
