import '@styles/main.scss';
import '@styles/global.scss';
import 'dayjs/locale/it';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { persistor, store } from '@store';

import { CustomThemeProvider } from '@containers';
import { FullScreenLoader } from '@components';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

function App({ Component, pageProps, router }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<FullScreenLoader />}>
        <CustomThemeProvider>
          <Component {...pageProps} key={router.asPath} />
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
