// import { orange } from '@mui/material/colors';
import commonThemeValues from './commons';

const MUI_LIGHT_THEME = {
  ...commonThemeValues,
  palette: {
    mode: 'light',
    primary: {
      main: '#bd2426',
      light: '#d31216',
      dark: '#d31216',
      constrastText: '#002129',
    },
    secondary: {
      main: '#002129',
      constrastText: '#002129',
    },
    action: {
      selected: '#d31216',
    },
  },
};

export default MUI_LIGHT_THEME;
