import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

const myObject = {
  totalValue: 0,
};


const Metres = ({ number, step, play }) => {
  const [total, setTotal] = useState(number);
  useEffect(() => {
    gsap.to(myObject, {
      duration: 1.5,
      totalValue: number,
      roundProps: 'totalValue',
      onUpdate: () => {
        setTotal(myObject.totalValue);
      },
    });
  }, [number]);
  return (
    <div className="absolute z-50 flex flex-col items-center justify-center space-y-2 right-5 top-8">
      <code
        className={classnames(
          'font-digital text-xl font-extrabold uppercase text-white transition-all ease-in-out'
        )}
      >
        {total}m
      </code>
      <div className="h-1.5 w-20 overflow-hidden rounded-xl bg-gray-200">
        <div
          className="h-1.5 bg-red-600 transition-all ease-in-out"
          style={{
            width: `${(step === 0 ? 0 : (step + 1) / 18) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};

export default Metres;
