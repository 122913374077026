import { setAlertReducer, setValidityReducer } from '@slices';

import InputCode from './../InputCode/InputCode';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

export default function Code({ setStep }) {
  const dispatch = useDispatch();

  const [statusButton, setStatusButton] = useState(false);
  const [ticketCode, setTicketCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlertOnInvalidCode, setShowAlertOnInvalidCode] = useState(false);

  const tick = new Audio('audio/tick.wav');
  const alarm = new Audio('audio/alarm.wav');

  const onCheckTicketCode = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        'https://mims.aditusculture.com/api/galata/order_items/additional_code/' +
          ticketCode
      );
      const data = await res.json();
      console.log(data);

      if (data?.success) {
        dispatch(setAlertReducer(false));
        setStep('audio');
        setLoading(false);
        setShowAlertOnInvalidCode(false);
        dispatch(setValidityReducer(data?.data?.validity));
      } else {
        alarm.play();
        dispatch(setAlertReducer(true));
        setLoading(false);
        setShowAlertOnInvalidCode(true);
      }
    } catch (error) {
      console.error('onCheckTicketCode', error);
    }
  };

  const onTriggerCheck = async () => {
    try {
      tick.play();
      await onCheckTicketCode();
    } catch (error) {
      console.error('onCheckTicketCode', error);
    }
  };

  return (
    <div className="absolute left-1/2 top-1/2 z-40 flex -translate-x-1/2 -translate-y-1/2 transform flex-col space-y-2">
      <div className="slide-in-bck-center flex w-full flex-row justify-around">
        <div className="flex flex-col space-y-6">
          {showAlertOnInvalidCode ? (
            <span className="slide-in-blurred-to text-primary">
              Invalid Code
            </span>
          ) : (
            <span className="flex w-full flex-col space-y-1">
              <code className="text-bold slide-in-blurred-to text-center text-sm uppercase text-white md:text-base">
                Insert ticket code, please
              </code>
            </span>
          )}

          <InputCode
            onEnableSubmit={setStatusButton}
            setTicketCode={setTicketCode}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={onTriggerCheck}
            disabled={!statusButton}
            className="mt-10 w-full bg-primary"
          >
            Send
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
