import { setTicketCodeReducer, ticketCodeSelector } from '@slices';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function InputCode({ onEnableSubmit, setTicketCode }) {
  // Store values
  const dispatch = useDispatch();
  const ticketCode = useSelector(ticketCodeSelector);
  const [code, setCode] = useState(ticketCode ? ticketCode.split('') : []);


  const beep = new Audio('audio/beep.wav');

  const focusOnNext = (e) => {
    beep.play();
    const index = Number(e.currentTarget.id.split('-')?.[1]);
    if (
      e.currentTarget.value &&
      e.currentTarget.value?.length > 0 &&
      e.currentTarget.nextSibling
    ) {
      console.log(e.currentTarget.nextSibling);
      e.currentTarget.nextSibling.disabled = false;
      e.currentTarget.nextSibling.focus();
    } //else e.currentTarget.previousSibling?.focus();

    let update = code;
    update[index] = e.currentTarget.value.toUpperCase();
    setCode([...update]);
  };

  useEffect(() => {
    const flag = code.length === 5 && code.every((c) => c.length > 0);
    onEnableSubmit(flag);
    if (flag) {
      setTicketCode(code.join(''));
      dispatch(setTicketCodeReducer(code.join('')));
    }
  }, [code]);

  return (
    <div className="flex flex-row items-center justify-between space-x-2">
      {(ticketCode ? ticketCode.split('') : [0, 1, 2, 3, 4]).map((c, i) => (
        <input
          key={i}
          id={'input-' + i}
          maxLength={1}
          //placeholder="#"
          onChange={focusOnNext}
          onKeyDown={(e) => {
            beep.play();
            let isCanc = e?.keyCode === 8 || e?.keyCode === 46;
            e?.keyCode === 37 && e.currentTarget.previousSibling
              ? e.currentTarget.previousSibling.focus()
              : null;
            e?.keyCode === 39 && e.currentTarget.nextSibling
              ? e.currentTarget.nextSibling.focus()
              : null;

            if (
              isCanc &&
              !e.currentTarget.value &&
              e.currentTarget.previousSibling
            ) {
              e.currentTarget.previousSibling.focus();
            }
          }}
          defaultValue={ticketCode?.[i] || null}
          className={`flex h-14 w-14 rounded-md bg-white/20 text-center font-digital text-4xl font-bold uppercase text-white backdrop-blur-lg input-${i}`}
        ></input>
      ))}
    </div>
  );
}
