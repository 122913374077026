import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl =
  'http://mims.aditusculture.com/api/galata/order_items/additional_code/';

const ServiceCode = createApi({
  reducerPath: 'code',
  tagTypes: ['Code'],
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Access-Control-Allow-Origin', '*');
    return headers;
  },
  baseQuery: fetchBaseQuery({
    mode: 'cors',
    baseUrl,
  }),
  onQueryStarted: () => {
    console.log('onQueryStarted SITES');
  },
  endpoints: (builder) => ({}),
});

export const { useGetSitesQuery } = ServiceCode;

export default ServiceCode;
