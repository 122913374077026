import CircularProgress from '@mui/material/CircularProgress';


export default function FullScreenLoader() {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 w-screen h-screen bg-black">
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <CircularProgress className="text-white fill-white" thickness={5} size={20} />
      </div>
    </div>
  );
}
