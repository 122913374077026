import { createSelector, createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    lang: 'en',
    ticketCode: null,
    validity: null,
    alert: false,
  },
  reducers: {
    setLangReducer(state, { payload }) {
      console.log({ payload });
      state.lang = payload;
    },
    setTicketCodeReducer(state, { payload }) {
      if (payload) {
        console.log({ payload });
        state.ticketCode = payload;
      }
    },
    setValidityReducer(state, { payload }) {
      console.log({ payload });
      state.validity = payload;
    },
    setAlertReducer(state, { payload }) {
      console.log({ payload });
      state.alert = payload;
    },
  },
});

export const {
  setLangReducer,
  setTicketCodeReducer,
  setValidityReducer,
  setAlertReducer,
} = userSlice.actions;

export const userSelector = createSelector(
  ({ user }) => user,
  (user) => user
);

export const currentLangSelector = createSelector(
  ({ user }) => user,
  ({ lang }) => lang
);

export const ticketCodeSelector = createSelector(
  ({ user }) => user,
  ({ ticketCode }) => ticketCode
);

export const validitySelector = createSelector(
  ({ user }) => user,
  ({ validity }) => validity
);

export const alertSelector = createSelector(
  ({ user }) => user,
  ({ alert }) => alert
);

export default userSlice.reducer;
