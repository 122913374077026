import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';

import { ServiceCode } from './services/code';
import { configureStore } from '@reduxjs/toolkit';
import rootReducers from './rootReducers';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  storage,
  level: 3,
  key: 'root',
  blacklist: ['navigation'],
};

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistReducer(persistConfig, rootReducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PURGE, REGISTER, PERSIST],
      },
    }).concat([ ]),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
