import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUI_LIGHT_THEME from './themes/mui.light.theme';


export const CustomThemeProvider = ({ children }) => {
  return (
    <ThemeProvider
      theme={createTheme(MUI_LIGHT_THEME)}
    >
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
