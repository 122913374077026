import classnames from 'classnames';

export default function Lang({ langRef, currentLang, onClickLang }) {
  return (
    <div className="absolute z-40 flex flex-col space-y-6 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <code className="text-sm text-center text-white uppercase text-bold slide-in-blurred-to md:text-base">
        Select language
      </code>
      <div
        ref={langRef}
        className="flex flex-row justify-around w-full fade-in"
      >
        <div className="flex flex-row items-center space-x-6">
          {['it', 'en', 'fr', 'de'].map((lang) => {
            return (
              <span
                key={lang}
                onClick={() => onClickLang(lang)}
                className={classnames(
                  'flex items-center justify-center',
                  'text-md font-semibold uppercase text-white',
                  'bg-white/20 backdrop-blur-lg',
                  'h-12 w-12 cursor-pointer rounded-md p-1',
                  'transition-all duration-150 ease-in-out',
                  { 'opacity-50': currentLang !== lang }
                )}
              >
                {lang}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}
